.alert-message-container {
    font-size: 12px;
    line-height: 1.5;
    padding: 1rem;
    background-color: #e0e0e0;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    margin-bottom: 12px;
}

.alert-message-heading {
    font-weight: bold;
    margin: 0 0 1rem 0;
}

.alert-message-description {
    margin: 0;
    text-align: justify;
}

.alert-message-link {
    display: block;
    text-align: center;
    margin-top: 1rem;
}
