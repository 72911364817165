.app-navbar {
    display: flex;
    flex-direction: row;
    padding: 12px 16px;
    background-color: #6D31AC;
    background-color: #351051;
}

.app-navbar-icon {
    width: 32px;
    height: 32px;
    padding: 4px;
}

.app-navbar-screen-title {
    color: white;
    font-size: 20px;
    font-weight: bolder;
    line-height: 32px;
    flex-grow: 1;
    margin: 0;
    padding: 0 8px;
    text-align: left;
}
